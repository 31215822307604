<template>
  <div>
    <base-card :loading="cardLoading">
      <div v-show="modifierList == ''" v-if="!cardLoading">
        <v-card-text class="text-center">
          <div class="pa-16">
            <v-btn
              class="mx-2"
              fab
              x-large
              disabled
              depressed
              style="font-size: 399px !important"
            >
              <v-icon x-large> mdi-format-list-checkbox </v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <div class="my-2">
              <span class="text-subtitle-1">No Modifier Found</span>
            </div>

            <v-spacer></v-spacer>
            <v-btn dark color="primary" @click="(title = 'Add'), openDialog()">
              <v-icon>mdi-plus</v-icon>
              Add Modifier
            </v-btn>
          </div>
        </v-card-text>
      </div>
      <div v-show="modifierList != ''">
        <v-card-title>
          <div class="d-flex justify-space-between flex-wrap">
            <v-btn
              class="ma-2"
              small
              dark
              color="primary"
              @click="(title = 'Add'), openDialog()"
            >
              <v-icon small>mdi-plus</v-icon>
              Add Modifier
            </v-btn>
            <div>
              <v-btn
                class="ma-2"
                small
                dark
                color="danger"
                v-if="selected != ''"
                @click="dialogDelete = true"
              >
                <v-icon small>mdi-delete</v-icon>
                delete
              </v-btn>
            </div>
          </div>
        </v-card-title>
        <v-card-title>
          <v-row>
            <v-col cols="12" lg="8">Modifier</v-col>
            <v-col cols="12" lg="4"
              ><v-text-field
                class="mt-n5"
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field
            ></v-col>
          </v-row>
        </v-card-title>
        <v-data-table
          v-model="selected"
          :search="search"
          :headers="headers"
          :items="modifierList"
          :page.sync="page"
          :items-per-page="itemsPerPage"
          @page-count="pageCount = $event"
          item-key="id"
          hide-default-footer
          @click:row="handleClick"
          show-select
        >
          <template v-slot:item.name="{ item }">
            <span class="font-weight-bold">{{ item.name }}</span> <br />
            <span
              v-for="(mod, index) in item.item"
              :item="mod"
              :index="index"
              :key="mod.mod_item_id"
              >{{ mod.name }}<span v-if="index < item.item.length - 1">, </span>
            </span>
          </template>
        </v-data-table>
        <div class="text-center py-2">
          <v-pagination v-model="page" :length="pageCount"></v-pagination>
        </div>
      </div>
    </base-card>

    <v-dialog v-model="dialog" eager width="400">
      <base-card>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <div class="mb-5">
              <span class="text-h6">{{ title }} Modifier</span>
            </div>
            <v-text-field
              prepend-icon="mdi-alphabetical-variant"
              label="Modifier Group Name"
              v-model="mName"
              :rules="[(v) => !!v || 'Modifier group name is required']"
            ></v-text-field>
            <v-select
              v-model="selectBranch"
              :items="branchList"
              multiple
              chips
              prepend-icon="mdi-store"
              item-text="name"
              item-value="branch_id"
              label="Select Branch"
              :rules="[(v) => !!v.length > 0 || 'Branch is required']"
            >
              <template v-slot:prepend-item>
                <v-list-item ripple @click="toggleForBranch">
                  <v-list-item-action>
                    <v-icon :color="selectBranch.length > 0 ? 'primary' : ''">
                      {{ iconForBranch }}
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title> Select All </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2"></v-divider>
              </template>
            </v-select>

            <v-text-field
              prepend-icon="mdi-format-list-numbered"
              v-model="sequenceNumber"
              type="number"
              :rules="[
                (v) =>
                  /null|^(|\d)+$/.test(v) ||
                  'Sequence number must be a positive number not decimal',
          
              ]"
              label="Sequence Number (optional)"
            >
              <template v-slot:append>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on"> mdi-help-circle-outline </v-icon>
                  </template>
                  Sequence of the modifier (Customer View)
                </v-tooltip>
              </template>
            </v-text-field>

            <draggable
              :list="list"
              item-key="mod_item_id"
              @change="onChange"
              class="mb-n1"
            >
              <v-list v-for="(element, idx) in list" :key="element.sequence">
                <v-row class="mb-n5">
                  <v-col cols="1"
                    ><v-icon class="row-pointer">mdi-menu</v-icon></v-col
                  >
                  <v-col cols="6">
                    <v-text-field
                      v-model="element.name"
                      class="mt-n4"
                      label="Option Name"
                      :rules="[(v) => !!v || 'Option name is required']"
                    ></v-text-field
                  ></v-col>
                  <v-col cols="3"
                    ><v-text-field
                      v-model="element.price"
                      label="Price"
                      class="mt-n4"
                      :rules="[
                        (v) => !!v || 'Required',
                        (v) => v >= 0 || 'Price should not be negative',
                      ]"
                      type="number"
                    ></v-text-field
                  ></v-col>

                  <v-col cols="1"
                    ><v-icon @click="removeAt(idx)">mdi-close</v-icon></v-col
                  >
                </v-row>
              </v-list>
            </draggable>
            <v-btn
              depressed
              class="ma-2 primary--text"
              color="lighten-5 primary"
              @click="add"
            >
              Add Option
            </v-btn>
            <v-select
              v-model="selectModifierDining"
              @change="setCompulsoryCheckBox()"
              :items="diningList"
              clearable
              chips
              prepend-icon="mdi-eye"
              item-text="name"
              item-value="dining_id"
              label="show only on (optional)"
            >
            </v-select>
            <!-- {{compulsoryCheckBox}} -->
            <v-checkbox
              v-if="selectModifierDining"
              v-model="compulsoryCheckBox"
              label="Compulsory Field"
              true-value="1"
              false-value="0"
              disabled
            ></v-checkbox>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            v-if="title == 'Edit'"
            @click="dialogDelete = true"
            text
            icon
            color="red lighten-2"
          >
            <v-icon>mdi-delete</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="dialogAction()" text> save </v-btn>
        </v-card-actions>
      </base-card>
    </v-dialog>
    <v-dialog v-model="dialogDelete" max-width="400px">
      <v-card>
        <v-card-title class="text-subtitle-1"
          >Are you sure you want to delete this Modifier?<br />
          All the product will lose the modifier too.</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogDelete = false"
            >Cancel</v-btn
          >
          <v-btn color="blue darken-1" @click="deleteModifier" text>OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" :color="snackbarColor" :timeout="timeout">
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn dark icon v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { BASEURL, BASESYNCURL } from "@/api/baseurl";
import axios from "axios";
import draggable from "vuedraggable";

export default {
  components: {
    draggable,
  },
  data() {
    return {
      compulsoryCheckBox: "0",
      diningList: [],
      domain: BASEURL,
      syncDomain: BASESYNCURL,
      snackbar: false,
      text: "",
      myArray: [],
      snackbarColor: "",
      timeout: 1900,
      companyID: "",
      headers: [
        {
          text: "Modifier Name",
          align: "start",
          sortable: true,
          value: "name",
        },
      ],
      modifierList: [],
      dialog: false,
      title: "",
      mName: "",
      valid: "",
      list: [{ name: "", price: "0.00", sequence: 1, mod_item_id: 0 }],
      selectBranch: [],
      branchList: [],
      selectModifier: "",
      selectModifierDining: "",
      sequenceNumber: "",
      cardLoading: true,
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      dialogDelete: false,
      search: "",
      selected: [],
      color: "#E0E0E0",
      swatches: [
        "#E0E0E0",
        "#F44366",
        "#E91E63",
        "#FF9800",
        "#CDDC39",
        "#4CAF50",
        "#2196F3",
        "#9C27B0",
      ],
    };
  },

  computed: {
    getAllBranchID() {
      var array = [];
      for (let i = 0; i < this.branchList.length; i++) {
        array.push(this.branchList[i].branch_id);
      }
      return array;
    },

    selectAllBranch() {
      return this.selectBranch.length === this.getAllBranchID.length;
    },

    selectSomeBranch() {
      return this.selectBranch.length > 0 && !this.selectAllBranch;
    },

    iconForBranch() {
      if (this.selectAllBranch) return "mdi-close-box";
      if (this.selectSomeBranch) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
  },

  created() {
    var CryptoJS = require("crypto-js");
    var x = localStorage.getItem("userInfo");
    // Decrypt
    var bytes = CryptoJS.AES.decrypt(x, "secret key 123");
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    this.companyID = decryptedData.company_id;
    this.getAllModifier();
    this.getAllDiningOption();
  },

  methods: {
    onChange(event) {
      this.reorder();
    },
    reorder() {
      this.list.forEach((item, index) => (item.sequence = index + 1));
    },

    removeAt(idx) {
      if (this.list.length == 1) {
        this.text = "At least one option for Modifier";
        this.snackbar = true;
        this.snackbarColor = "warning";
      } else {
        this.list.splice(idx, 1);
      }
    },
    add: function () {
      if (this.$refs.form.validate()) {
        var sequence = this.list.length;
        sequence = sequence + 1;
        this.list.push({
          name: "",
          price: "0.00",
          sequence: sequence,
          mod_item_id: 0,
        });
      }
    },

    setCompulsoryCheckBox() {
      if (this.selectModifierDining != "") {
        this.compulsoryCheckBox = "1";
      } else {
        this.compulsoryCheckBox = "0";
      }
    },

    getAllDiningOption() {
      const params = new URLSearchParams();
      params.append("getAllDiningOption", "done");
      params.append("company_id", this.companyID);

      axios({
        method: "post",
        url: this.domain + "/dining/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.diningList = response.data.dining;
          } else {
            console.log("no list get");
            this.diningList = [];
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getCompanyBranch() {
      const params = new URLSearchParams();
      params.append("getCompanyBranch", "done");
      params.append("company_id", this.companyID);

      axios({
        method: "post",
        url: this.domain + "/branch/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.branchList = response.data.branch;
          } else {
            this.branchList = [];
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    toggleForBranch() {
      this.$nextTick(() => {
        if (this.selectAllBranch) {
          this.selectBranch = [];
        } else {
          this.selectBranch = this.getAllBranchID.slice();
        }
      });
    },

    sendNotication(branch_id) {
      const params = new URLSearchParams();
      params.append("send_notification", "done");
      params.append("branch_id", branch_id);
      params.append("type", "1");

      axios({
        method: "post",
        url: this.syncDomain + "/sync/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            console.log("Send Notification successfully");
          } else {
            console.log("Send Notification fail");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getAllModifier() {
      const params = new URLSearchParams();
      params.append("getAllModifier", "done");
      params.append("company_id", this.companyID);

      axios({
        method: "post",
        url: this.domain + "/modifier/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            this.modifierList = response.data.modifier;
            this.cardLoading = false;
          } else {
            this.modifierList = [];
            this.cardLoading = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    openDialog() {
      this.getCompanyBranch();
      if (this.title == "Add") {
        this.dialog = true;
        this.list = [{ name: "", price: "0.00", sequence: 1, mod_item_id: 0 }];
        process.nextTick(() => {
          this.$refs.form.reset();
        });
      } else {
        this.dialog = true;
      }
    },

    handleClick(item) {
      var array = [];
      this.mName = item.name;
      for (let i = 0; i < item.branch.length; i++) {
        array.push(parseInt(item.branch[i].id));
      }

      this.selectBranch = array;
      this.list = item.item;
      this.sequenceNumber = item.sequence_number;
      this.selectModifier = item.id;
      this.selectModifierDining = parseInt(item.dining_id);
      this.compulsoryCheckBox = item.compulsory;
      this.title = "Edit";
      this.openDialog();
    },

    getBranchForNotification(mod_group_id) {
      const params = new URLSearchParams();
      params.append("getModifierBranch", "done");
      params.append("mod_group_id", mod_group_id);
      axios({
        method: "post",
        url: this.domain + "/modifier/index.php",
        data: params,
      })
        .then((response) => {
          console.log(response);
          if (response.data.status == "1") {
            var branchList = response.data.modifier;
            for (let i = 0; i < branchList.length; i++) {
              this.sendNotication(branchList[i].id);
            }
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    deleteModifier() {
      if (this.selected != "") {
        const array = [];
        for (let i = 0; i < this.selected.length; i++) {
          array.push(this.selected[i].id);
        }
        const params = new URLSearchParams();
        params.append("deleteMultiMod", "done");
        params.append("array_mod_group_id", JSON.stringify(array));
        axios({
          method: "post",
          url: this.domain + "/modifier/index.php",
          data: params,
        })
          .then((response) => {
            console.log(response);
            if (response.data.status == "1") {
              this.dialog = false;
              this.text = "Successfully delete";
              this.snackbar = true;
              this.snackbarColor = "success";
              this.selected = [];
              this.dialogDelete = false;
              this.getAllModifier();
              for (let j = 0; j < array.length; j++) {
                this.getBranchForNotification(array[j]);
              }
            } else {
              this.text = "Please try again later";
              this.snackbar = true;
              this.snackbarColor = "error";
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        const params = new URLSearchParams();
        params.append("delete", "done");
        params.append("mod_group_id", this.selectModifier);
        axios({
          method: "post",
          url: this.domain + "/modifier/index.php",
          data: params,
        })
          .then((response) => {
            console.log(response);
            if (response.data.status == "1") {
              this.dialog = false;
              this.text = "Successfully delete";
              this.snackbar = true;
              this.snackbarColor = "success";
              this.dialogDelete = false;
              this.getAllModifier();
              this.getBranchForNotification(this.selectModifier);
            } else {
              this.text = "Please try again later";
              this.snackbar = true;
              this.snackbarColor = "error";
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },

    dialogAction() {
      if (this.title == "Add") {
        if (this.$refs.form.validate()) {
          const params = new URLSearchParams();
          params.append("addModifier", "done");
          params.append("company_id", this.companyID);
          params.append("name", this.mName);
          params.append("sequence_number", this.sequenceNumber == null ? "" : this.sequenceNumber);
          params.append("branch_array_list", JSON.stringify(this.selectBranch));
          params.append("list", JSON.stringify(this.list));
          params.append(
            "dining_id",
            this.selectModifierDining ? this.selectModifierDining : ""
          );
          params.append("compulsory", this.selectModifierDining ? "1" : "0");

          axios({
            method: "post",
            url: this.domain + "/modifier/index.php",
            data: params,
          })
            .then((response) => {
              console.log(response);
              if (response.data.status == "1") {
                this.dialog = false;
                this.text = "Successfully add";
                this.snackbar = true;
                this.snackbarColor = "success";
                this.getAllModifier();
                for (let i = 0; i < this.selectBranch.length; i++) {
                  this.sendNotication(this.selectBranch[i]);
                }
              } else {
                this.text = "Please try again later";
                this.snackbar = true;
                this.snackbarColor = "error";
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      } else {
        if (this.$refs.form.validate()) {
          const params = new URLSearchParams();
          params.append("editModifier", "done");
          params.append("name", this.mName);
          params.append("id", this.selectModifier);
          params.append("sequence_number", this.sequenceNumber == null ? "" : this.sequenceNumber);
          params.append("branch_array_list", JSON.stringify(this.selectBranch));
          params.append("list", JSON.stringify(this.list));
          params.append(
            "dining_id",
            this.selectModifierDining ? this.selectModifierDining : ""
          );
          params.append("compulsory", this.selectModifierDining ? "1" : "0");

          axios({
            method: "post",
            url: this.domain + "/modifier/index.php",
            data: params,
          })
            .then((response) => {
              console.log(response);
              if (response.data.status == "1") {
                this.dialog = false;
                this.text = "Successfully edit";
                this.snackbar = true;
                this.snackbarColor = "success";
                this.getAllModifier();
                for (let i = 0; i < this.selectBranch.length; i++) {
                  this.sendNotication(this.selectBranch[i]);
                }
              } else {
                this.text = "Please try again later";
                this.snackbar = true;
                this.snackbarColor = "error";
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
    },
  },
};
</script>

<style>
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
.row-pointer {
  cursor: pointer;
}
</style>